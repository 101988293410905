<template>
  <v-container>
    <form>
      <v-row>
        <v-col cols="12" md="4" v-if="!$v.form.isPrimary.$model">
          <v-text-field
            :value="value.name"
            v-model.trim="$v.form.name.$model"
            label="Name"
            required
            :error-messages="handleErrors($v.form.name)"
            @blur="$v.form.name.$touch()"
            @input="handleInput"
            :readonly="readonly"
            :disabled="disabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" v-if="!$v.form.isPrimary.$model">
          <v-text-field
            :value="value.relationship"
            v-model.trim="$v.form.relationship.$model"
            label="Relationship"
            required
            :error-messages="handleErrors($v.form.relationship)"
            @blur="$v.form.relationship.$touch()"
            @input="handleInput"
            :readonly="readonly"
            :disabled="disabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-checkbox
            :value="value.isPrimary"
            v-model="$v.form.isPrimary.$model"
            label="Primary"
            required
            :disabled="disabled"
            @change="handlePrimary"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <!-- <v-text-field
            :value="value.phone"
            v-model.trim="$v.form.phone.$model"
            label="Phone Number"
            required
            :error-messages="handleErrors($v.form.phone)"
            @blur="$v.form.phone.$touch()"
            @input="handleInput"
            :readonly="readonly"
            :disabled="disabled"
          ></v-text-field> -->
          <phone-number
            :value="value.phone"
            v-model.trim="$v.form.phone.$model"
            @blur="$v.form.phone.$touch()"
            @input="handleInput"
            :readonly="readonly"
            :disabled="disabled"
          ></phone-number>
        </v-col>
        <v-col cols="12" md="2">
          <v-checkbox
            :value="value.canText"
            v-model="$v.form.canText.$model"
            label="Text"
            required
            :disabled="disabled"
            @change="$v.form.canText.$touch()"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            :value="value.ext"
            v-model.trim="$v.form.ext.$model"
            label="Extension"
            required
            :error-messages="handleErrors($v.form.ext)"
            @blur="$v.form.ext.$touch()"
            @input="handleInput"
            :readonly="readonly"
            :disabled="disabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            :value="value.email"
            v-model.trim="$v.form.email.$model"
            label="Email"
            required
            :error-messages="handleErrors($v.form.email)"
            @blur="$v.form.email.$touch()"
            @input="handleInput"
            :readonly="readonly"
            :disabled="disabled"
          ></v-text-field>
        </v-col>
      </v-row>
    </form>
  </v-container>
</template>

<script lang="ts">
import { validationMixin } from 'vuelidate';
import { helpers, maxLength, minLength, required, requiredIf, numeric, between } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';
import PhoneNumber from './PhoneNumber.vue';

const newContact = {
  name: '',
  isPrimary: false,
  relationship: '',
  phone: '',
  ext: '',
  canText: true,
  email: '',
  seq: '',
};

export default {
  name: 'ContactForm',
  mixins: [validationMixin],
  components: { PhoneNumber },
  props: {
    value: {
      type: Object,
      default: () => newContact,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    required: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => ({
    handleErrors,
    id: null,
    form: newContact,
  }),
  validations: {
    form: {
      name: {},
      isPrimary: {},
      relationship: {},
      type: {},
      phone: {},
      ext: {},
      canText: {},
      email: {},
      seq: {},
    },
  },
  methods: {
    clear() {
      this.form = newContact;
    },
    handleInput() {
      this.$emit('input', this.$v.form.$model);
    },
    handlePrimary() {
      this.$v.form.isPrimary.$touch();
      this.$emit('input', this.$v.form.$model);
      this.$emit('handlePrimary', this.$v.form.isPrimary.$model);
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.$v.$reset();
        this.id = value.id;
        this.form = { ...newContact, ...value };
        this.handleInput();
      },
    },
  },
};
</script>
