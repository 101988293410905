<template>
  <setup-table
    :title="`Driver`"
    :items="items"
    :headers="headers"
    :loading="loading"
    :clearItem="!showForm || !editedItem.id"
    @createItem="createItem()"
    @editItem="editItem"
    @deleteItems="deleteItems"
    showSearch
    pagination
  >
    <template #itemForm v-if="showForm">
      <driver-form
        ref="driverForm"
        :value="editedItem"
        :config="config"
        :vehicles="vehicles"
        :zoneItems="zoneItems"
        :designationItems="designationItems"
        :positionItems="positionItems"
        :indicatorItems="indicatorItems"
        @submit="saveItem"
        @closeForm="closeForm"
      ></driver-form>
    </template>

    <template #[`item.synced`]="{ item }">
      <v-icon small v-if="item.synced">mdi-sync</v-icon>
    </template>
    <template #[`item.ft`]="{ item }">
      <p class="mb-0">{{ item.fulltime ? 'Full-Time' : 'Part-Time' }}</p>
    </template>
    <template #[`item.status`]="{ item }">
      <p class="mb-0">{{ item.active ? 'Active' : 'Inactive' }}</p>
    </template>
    <template #[`item.type`]="{ item }">
      <v-icon small v-if="item.schoolId">mdi-school</v-icon>
      <v-icon small v-if="item.depotId">mdi-bus</v-icon>
    </template>
  </setup-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_DRIVERS, SAVE_DRIVER, DELETE_DRIVERS } from '@/store/modules/Driver/actions';
import { GET_ZONES } from '@/store/modules/Location/actions';
import { GET_CONFIG } from '@/store/modules/Config/actions';
import SetupTable from './SetupTable.vue';
import DriverForm from './DriverForm.vue';

export default {
  name: 'DriverSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, DriverForm },
  data() {
    return {
      showForm: false,
      items: [],
      editedItem: {},
      config: {},
      loading: true,
    };
  },
  computed: {
    ...mapGetters('app', ['isRoutingClient']),
    ...mapGetters('driver', ['drivers', 'designationItems', 'positionItems', 'indicatorItems']),
    ...mapGetters('location', ['zoneItems']),
    ...mapGetters('vehicle', ['vehicles']),
    headers() {
      const h = [
        { text: 'Name', value: 'fullName' },
        // {
        //   text: 'Email',
        //   value: 'contact.email',
        // },
        // { text: 'Phone', value: 'contact.phone' },
        { text: 'Zone', value: 'zone' },
        { text: 'Designation', value: 'designation' },
        { text: 'Full/Part Time', value: 'ft' },
        { text: 'Status', value: 'status' },
      ];
      if (this.isRoutingClient) h.unshift({ text: '', value: 'synced' });
      return h;
    },
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('driver', [GET_DRIVERS, SAVE_DRIVER, DELETE_DRIVERS]),
    ...mapActions('location', [GET_ZONES]),
    ...mapActions('config', [GET_CONFIG]),
    async fetchItems() {
      await this.getDrivers({ forTable: true });
      this.items = this.drivers;
      await this.getZones();
      this.config = await this.getConfig('driver');
      this.loading = false;
    },
    createItem() {
      this.editedItem = { id: 0 };
      this.showForm = true;
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const r = await this.saveDriver(item);
        if (r && r.id) {
          this.$myalert.success('Driver saved');
          await this.fetchItems();
          this.editedItem = { ...item, id: r.id };
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Drivers' : 'this Driver'
        }? This operation cannot be undone.`
      );
      if (yes) {
        try {
          const r = await this.deleteDrivers(items.map((e) => e.id));
          if (r.done) {
            this.$myalert.success(`${items.length > 1 ? 'Drivers' : 'Driver'} deleted`);
            this.showForm = false;
            this.fetchItems();
          }
        } catch (error) {
          this.$myalert.error(error.message);
        }
      }
    },
    closeForm() {
      this.showForm = false;
    },
    manageDriverSLA() {
      this.$refs.slaManager.dialog = true;
    },
  },
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
.v-data-table-header {
  background-color: #f5f5f5 !important;
}
</style>
